

img {
  width: 100vw;
  height: 100vh;
}

/* Section 1 */
.section1 {
  background-image: url("assets/images/home.jpg");
  background-size: cover; /* Cover the entire section */
  background-position: center center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat; /* Do not repeat the image */
}



/* Reset some default browser styles for consistency */
body, h1, h2, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Set a background color for the entire page */
body {
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

/* Header styles */
header {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

h1 {
  font-size: 2rem;
}

/* Navigation menu styles */
nav {
  background-color: #222;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 15px;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #ff6600;
}

/* Main content container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Section styles */
.section {
  background-color: #fff;
  padding: 30px;
  margin: 20px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* Button styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6600;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e25500;
}

/* Footer styles */
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}

/* Responsive styles (example) */
@media (max-width: 768px) {
  header {
    padding: 10px 0;
  }

  nav ul {
    flex-direction: column;
    text-align: center;
  }

  nav li {
    margin: 10px 0;
  }
}
